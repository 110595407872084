<template>
  <div class="home">
    <Header ref="headerView" :index.sync="active"></Header>
    <div class="nav-box">
      <el-carousel arrow="never" indicator-position="none" height="50vh">
        <el-carousel-item v-for="(item,index) in carouselList" :key="index">
          <div class="banner-content">
            <el-image
                class="big-banner-img"
                :src="require('@/assets/images/banner56.png')"
                fit="cover">
              <div slot="placeholder" class="image-slot"></div>
            </el-image>
            <div class="banner-text color-w flex flex-column justify-end text-left"
                 style="padding-left: 2%;padding-bottom: 1%">
              <h1 class="text-bold">深耕糖业20年</h1>
              <h2 class="margin-top-xl">
                专注企业信息化建设，用科技为企业赋能。</h2>
            </div>
            <div class="banner-animation ">
              <div class="orbit-container">
                <div class="orbit3"></div>
                <div class="orbit2"></div>
                <div class="orbit1"></div>
                <div class="orbit"></div>
                <div class="icon-container">
                  <div class="icon icon-round1"></div>
                  <div class="icon icon-round2"></div>
                  <div class="icon icon-round3"></div>
                  <div class="icon icon-round4"></div>
                  <el-image
                      class="img-36 icon icon1"
                      :src="require('@/assets/images/icon54.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-60 icon icon2"
                      :src="require('@/assets/images/icon55.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-60 icon icon3"
                      :src="require('@/assets/images/icon56.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-60 icon icon4"
                      :src="require('@/assets/images/icon63.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-90 icon icon5"
                      :src="require('@/assets/images/icon57.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-90 icon icon6"
                      :src="require('@/assets/images/icon58.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-90 icon icon7"
                      :src="require('@/assets/images/icon59.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-120 icon icon8"
                      :src="require('@/assets/images/icon60.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-120 icon icon9"
                      :src="require('@/assets/images/icon61.png')"
                      fit="cover"></el-image>
                  <el-image
                      class="img-120 icon icon10"
                      :src="require('@/assets/images/icon62.png')"
                      fit="cover"></el-image>
                </div>
              </div>
            </div>
          </div>
          <!--                <div ref="swiperBox" class=" flex flex-column justify-between swiper-box"
                               :class="index%2===0?'swiper-bg1':'swiper-bg2'">
                            <div class="text-left color-3 swiper-item text-xl">
                              <div class="text-36 ">{{ item.title }}</div>
                              <div class="color-6">{{ item.engTitle }}</div>
                              <div style="margin-top: 6%">{{ item.content }}</div>
                              <div class="text-sm flex flex-wrap color-9" style="margin-top: 0.625rem">
                                <div v-for="(subItem,subIndex) in item.other" :key="subIndex">{{ subItem }}<span
                                    v-if="subIndex!==item.other.length-1">、</span></div>
                              </div>
                            </div>
                            <div class="flex flex-wrap justify-center">
                              <div class="margin-bottom-xl text-btn text-left">
                                <el-divider></el-divider>
                                <el-button class=" text-lg btn-box" style="color: #FFFFFF;border: none;background: var(&#45;&#45;theme-color)"
                                           @click="gotoUrl(index%2===0?4:5)">
                                  了解更多
                                </el-button>
                                &lt;!&ndash;                <div class="btn-box" @click="gotoUrl(index%2===0?4:5)">了解更多</div>&ndash;&gt;
                              </div>
                              <div class="text-right banner-img-box">
                                <el-image
                                    class="banner-img margin-0 "
                                    :src="item.img"
                                    fit="cover"></el-image>
                              </div>
                            </div>
                          </div>-->
        </el-carousel-item>
      </el-carousel>
    </div>

    <h1 class="text-28 text-bold color-0 margin-top-xxl">我们的产品</h1>
    <p class="color-6 margin-top">深耕行业，创新引领发展，为企业赢得先机</p>
    <div class="product-box flex flex-wrap justify-content-between align-center">
      <div class="product-item-left margin-top-xl">
        <div class="padding-xl product-img-box">
          <el-image
              class="product-item-img"
              style="width: 100%"
              :src="require('@/assets/images/banner9-4.jpg')"
              fit="cover"></el-image>
        </div>
        <div class="padding-xl mask text-left color-w">
          <h2 class="  margin-top-xl text-bold">糖厂农务管理系统</h2>
          <p class="margin-top-sm text-sm">Agricultural Service Management System</p>
          <p class="margin-top-xl " style="text-indent: 2em;min-height: 5rem">
            甘蔗种植和砍运业务的一体化管理系统，功能包括：地块管理、种植作业、预付农贷、面积、估产;抽签、计划、发证、报蔗、派车、蔗检、过磅、结算和付款等，是制糖企业生产供应链的基础与核心系统。
          </p>
          <p class="text-sm margin-top" style="white-space: pre-wrap;">
            Web、App、微信小程序&nbsp;&nbsp;|&nbsp;&nbsp;集团管理&nbsp;&nbsp;|&nbsp;&nbsp;车辆定位跟踪、云排队&nbsp;&nbsp;|&nbsp;&nbsp;种植GIS、大数据&nbsp;&nbsp;|&nbsp;&nbsp;自动派车、自动过磅</p>
          <div class="flex mask-content margin-top-xxl">
            <el-button round class="text-df" type="success" style="background: var(--theme-color)"
                       @click="gotoChat">在线咨询
            </el-button>
            <el-button round class="text-df" @click="gotoUrl(4)">了解详情</el-button>

          </div>

        </div>
      </div>
      <div class="product-item-right margin-top-xl">
        <div class="padding-xl product-img-box">
          <el-image
              class="product-item-img"
              style="width: 100%"
              :src="require('@/assets/images/img5.png')"
              fit="cover"></el-image>
        </div>
        <div class="padding-xl mask text-left color-w">
          <h2 class="  margin-top-xl text-bold">无人值守过磅管理系统</h2>
          <p class="margin-top-sm text-sm">Unmanned Weighing Management System</p>
          <p class="margin-top-xl " style="text-indent: 2em;min-height: 5rem">
            系统充分融入车牌自动识别、语音、视频、红外侦测、自动控制等先进技术，无需工作人员操作，系统运行安全、稳定、高效，为用户实现降本增效，是新一代通用物资过磅系统。
          </p>
          <p class="text-sm margin-top">
            7*24全天作业&nbsp;&nbsp;|&nbsp;&nbsp;安全、稳定、高效&nbsp;&nbsp;|&nbsp;&nbsp;Web、App远程监控&nbsp;&nbsp;|&nbsp;&nbsp;多磅协同</p>
          <div class="flex mask-content margin-top-xxl ">
            <el-button round class="text-df" type="success" style="background: var(--theme-color)"
                       @click="gotoChat">在线咨询
            </el-button>
            <el-button round class="text-df" @click="gotoUrl(5)">了解详情</el-button>

          </div>
        </div>
      </div>
    </div>
    <h1 class="text-28 text-bold color-0 margin-top-xxl ">我们的服务</h1>
    <p class="color-6 margin-top margin-bottom-xl">定制开发——适合自己的才是最好的</p>
    <div class="service-box color-w" @click="gotoUrl(6)">
      <h1 class="margin-top">软件开发一站式服务平台</h1>
      <div class="flex justify-center align-center flex-wrap padding-top-xl">
        <div class="margin-lr-lg margin-top" v-for="(item,index) in featuresList" :key="index"
             style="width: 5%;flex-basis: 5rem">
          <el-image
              class="img-36"
              :src="item.icon"
              fit="fill"></el-image>
          <div class=" text-bold margin-top-xs">{{ item.name }}</div>
          <!--        <div class="text-xl color-4" style="margin-top: 2.5rem">{{ item.content }}</div>-->
        </div>
      </div>
    </div>
    <!--    <div class="flex flex-wrap justify-center align-center service-box">
          <div class="flex-sub">
            <el-image
                class="img-500"
                :src="require('@/assets/images/banner18.png')"
                fit="fill"></el-image>
          </div>
          <div class="flex-sub introduce-box text-left  flex  justify-center align-center">
            <div class="margin-xl introduce-item">
              <h2 class="">管理软件开发</h2>
              <p class="color-6 margin-top-xxl ">
                桌面、web等多客户端应用软件定制开发，二十年的软件开发带来丰富的技术沉淀积累，应用技术涵盖：各类终端平台技术开发、GIS/GPS、视频监控、RFID、电子车牌、自动化系统等，专业提升价值</p>
              <el-button class=" text-df btn-box margin-top-xl "
                         style="color: #FFFFFF;border: none;background: var(&#45;&#45;theme-color)">
                在线咨询
              </el-button>
              <el-button round class="text-df " style="width: 8rem" @click="gotoUrl(5)">了解详情</el-button>

            </div>
          </div>
        </div>
        <div class="flex align-center service-box" style="flex-wrap: wrap-reverse">
          <div class="flex-sub introduce-box text-left flex justify-center align-center">
            <div class="margin-xl introduce-item">
              <h2 class="">移动端开发</h2>
              <p class="color-6 margin-top-xxl ">
                擅长原生android、Uniapp、微信公众号、微信小程序等移动端应用模式开发</p>
              <el-button class=" text-df btn-box margin-top-xl "
                         style="color: #FFFFFF;border: none;background: var(&#45;&#45;theme-color)">
                在线咨询
              </el-button>
              <el-button round class="text-df " style="width: 8rem" @click="gotoUrl(5)">了解详情</el-button>

            </div>

          </div>
          <div class="flex-sub">
            <el-image
                class="img-160-w"
                :src="require('@/assets/images/banner19.png')"
                fit="fill"></el-image>
          </div>
        </div>-->
    <h1 class="text-28 text-bold color-0 margin-top-xxl">为什么选择我们？</h1>
    <div style="width: 100%;" class="flex justify-center align-center flex-wrap padding-top-xl">
      <div class="list-item margin-lr-lg" v-for="(item,index) in list" :key="index">
        <el-image
            class="img-120"
            :src="item.img"
            fit="fill"></el-image>
        <div class="text-xxl color-0 text-bold margin-top-xl">{{ item.title }}</div>
        <p class="text-df margin-top content-box text-left" style="text-indent: 2em;">{{ item.content }}</p>
      </div>
    </div>
    <h1 class="text-28 text-bold color-0 margin-top-xxl">客户评价</h1>
    <div class="evaluate-box" style="margin: 1.875rem auto 0;">
      <el-carousel ref="carousel" arrow="never" indicator-position="none" :interval="2000" :type="carouselType"
                   height="26rem"
                   style="width: 80%;margin: 0 auto; ">
        <el-carousel-item v-for="(item,index) in evaluateList" :key="index">
          <div class="evaluate-item">
            <div class="flex">
              <div class="margin-right">
                <el-image
                    class="evaluate-img"
                    :src="item.img"
                    fit="contain"></el-image>
              </div>
              <div class="flex flex-column justify-between text-left">
                <div class="text-xl" style="color: var(--theme-color)">{{ item.company }}</div>
                <div class="text-xs color-6">{{ item.name }}</div>
              </div>
            </div>
            <div class="margin-top text-left" style="color: #DEDEDE">————</div>
            <div class="margin-top text-sm color-3" style="height: 10rem">{{ item.content }}</div>
            <el-rate
                :value="5"
                disabled
                text-color="#ff9900">
            </el-rate>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="flex justify-center align-center margin-top-xl">
      <i class="el-icon-arrow-left left-box margin-lr-sm" @click="previousOne"></i>
      <i class="el-icon-arrow-right left-box margin-lr-sm" @click="nextOne"></i>
    </div>
    <Footer></Footer>
    <FloatBtn></FloatBtn>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import FloatBtn from '@/components/FloatBtn.vue'

export default {
  name: 'HomeView',
  components: {
    // HelloWorld
    Header,
    Footer,
    FloatBtn
  },
  data() {
    return {
      active: 0,
      bannerHeight: 600,
      carouselType: 'card',
      carouselList: [
        {
          title: '糖厂农务管理系统',
          engTitle: 'Agricultural Service Management System',
          content: '甘蔗种植和砍运业务的一体化管理系统，功能包括：地块管理、种植作业、预付农贷、面积、估产;抽签、计划、发证、报蔗、派车、蔗检、过磅、结算和付款等，是制糖企业生产供应链的基础与核心系统。',
          other: ['Web', 'App', '微信小程序/集团管理/车辆定位跟踪', '云排队/种植GIS', '大数据/自动派车', '过磅'],
          img: require('@/assets/images/banner1.jpeg')
        }
      ],
      featuresList: [
        {
          name: '系统软件',
          icon: require('@/assets/images/icon48.png'),
        },
        {
          name: 'APP',
          icon: require('@/assets/images/icon49.png'),
        },
        {
          name: '小程序',
          icon: require('@/assets/images/icon50.png'),
        },
        {
          name: '公众号',
          icon: require('@/assets/images/icon51.png'),
        },
        {
          name: '企业网站',
          icon: require('@/assets/images/icon52.png'),
        },
        {
          name: '物联网',
          icon: require('@/assets/images/icon53.png'),
        },
      ],
      list: [
        {
          title: '深耕行业 精通业务',
          content: '2005年公司创立以来，一直致力于糖企的农务信息化建设，秉承技术为管理服务的理念，不断创新发展，持续助力企业管理的提升。',
          img: require('@/assets/images/icon2.png')
        },
        {
          title: '与时俱进 技术领先',
          content: '公司秉承创新发展的理念，紧跟时代步伐，通过引入新技术，持续不断的提升软件能力，为管理赋能。',
          img: require('@/assets/images/icon43.png')
        },

        {
          title: '产品+个性化定制',
          content: '我们始终认为，万物皆为共性与个性的统一，每一次超越和进步都是个性的突破，软件亦然，在普遍性的基础上，为企业量身定制，倾力打造企业核心竞争力。',
          img: require('@/assets/images/icon1.png')
        },
        {
          title: '7*24小时放心服务',
          content: '公司一直坚守服务至上原则，提供7*24小时服务热线，通过远程网络、电话、即时通信等各种方式，随时随地为您提供贴心服务。',
          img: require('@/assets/images/icon4.png')
        }],
      evaluateList: [
        {
          company: '集团公司',
          name: '农业部黄经理',
          content: '系统功能强大，操作人性化，使用便捷，查询速度快，业务预警很适用，我随时随地都能查看各厂的砍运报表，使我能决策于千里之外。',
          img: require('@/assets/images/icon44.png')
        },
        {
          company: '明阳制糖分厂',
          name: '农务李副总经理',
          content: '车辆定位跟踪对砍运指挥帮助巨大，云排队功能大大节省司机排队时间，可以睡个好觉了，行车也更安全。',
          img: require('@/assets/images/icon44.png')
        },
        {
          company: '广西博宣食品有限公司',
          name: '农业部魏经理',
          content: '自动发证很好用，半个小时不到我就能完成一天的计划和发证业务；自动派车公平合理错，司机再也不必走后门了。',
          img: require('@/assets/images/icon47.png')
        },
        {
          company: '广西博庆食品有限公司',
          name: '农业部韦经理',
          content: '农务系统（集团版）上线后，能够实现内部业务管理流程规范化，使得数据的收集、整理和分析也变得更加高效和准确。整个过程中，服务团队都展现了非常高的专业性和高效性。',
          img: require('@/assets/images/icon45.png')
        }
      ]
    }
  },
  mounted() {
    this.updateCarouselType();
    window.addEventListener('resize', this.updateCarouselType);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCarouselType);
  },
  methods: {
    updateCarouselType() {
      const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      if (width < 800) {
        this.carouselType = 'default'; // 当屏幕宽度小于600px时，‌使用默认的滑动式
      } else {
        this.carouselType = 'card'; // 否则使用卡片式
      }
    },
    gotoUrl(index) {
      this.$refs.headerView.gotoNav(index)
    },
    previousOne() {
      this.$refs.carousel.setActiveItem(this.$refs.carousel.activeIndex - 1);
    },
    nextOne() {
      this.$refs.carousel.setActiveItem(this.$refs.carousel.activeIndex + 1);
    },
    gotoChat() {
      window.open(window.imUrl + '/#/imclient', '_blank');
    },
  }
}
</script>
<style scoped lang="scss">
.home {
  background: #FAFAFA;
}

.nav-box {
  height: 50vh;
  //background-image: url("../assets/images/6.png");
  //background-size: 100% 100%;
  //background-repeat: no-repeat;
}

.btn-box {
  width: 8rem;
  //height: 2.8125rem;
  //line-height: 2.8125rem;
  border-radius: 4.6875rem;
  background: rgba(247, 171, 115, 1);
  color: #FFFFFF;
}

.orbit-container {
  position: relative;
}

.orbit {
  position: absolute;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  top: 15rem;
  left: 15rem;
}

.orbit1 {
  position: absolute;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  width: 20rem;
  height: 20rem;
  top: 10rem;
  left: 10rem;
}

.orbit2 {
  position: absolute;
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  width: 30rem;
  height: 30rem;
  top: 5rem;
  left: 5rem;
}

.orbit3 {
  border: 1px solid #FFFFFF;
  border-radius: 50%;
  width: 40rem;
  height: 40rem;
}

.icon-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 40rem;
  height: 40rem;
  transform-origin: 20rem 20rem;
  animation: orbit 30s linear infinite;
}

.icon-round1 {
  width: 1rem;
  height: 1rem;
  background: #FFFFFF;
  top: 50%;
  left: 50%;
}

.icon-round2 {
  width: 2rem;
  height: 2rem;
  background: #FFFFFF;
  top: 14.5rem;
  left: 17.5rem;
}
.icon-round3 {
  width: 2.5rem;
  height: 2.5rem;
  background: #FFFFFF;
  top: 38.5rem;
  left: 17.5rem;
}
.icon-round4 {
  width: 3.5rem;
  height: 3.5rem;
  background: #FFFFFF;
  top: 2.5rem;
  left: 6.5rem;
}
.icon {
  position: absolute;
  border-radius: 50%;
  animation: orbit1 30s linear infinite;
}

.icon1 {
  left: 19.25rem;
  top: 23.75rem;
}

.icon2 {
  left: 10.25rem;
  top: 23.75rem;
}

.icon3 {
  left: 28rem;
  top: 19.75rem;
}

.icon4 {
  left: 18rem;
  top: 8.25rem;
}

.icon5 {
  left: 3rem;
  top: 13.75rem;
}

.icon6 {
  left: 21rem;
  top: 31.5rem;
}

.icon7 {
  left: 30rem;
  top: 9.5rem;
}

.icon8 {
  left: 0;
  top: 27.5rem;
}

.icon9 {
  left: 35.25rem;
  top: 22.5rem;
}

.icon10 {
  left: 17rem;
  top: -3.75rem;
}

@keyframes orbit {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes orbit1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.swiper-box {
  width: 100%;
  height: 70vh;
  padding-bottom: 1rem;
  //background: linear-gradient(-150deg, #B9EDD3, #ffffff, #ffffff);
  //border-radius: 0 0 0 10rem;
  background-image: url("../assets/images/banner2.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.swiper-bg1 {
  background-image: url("../assets/images/bgImg18.png");
}

.swiper-bg2 {
  background-image: url("../assets/images/bgImg17.png");
}

.service-box {
  width: 80vw;
  margin: 2rem auto 0;
  padding: 2.5rem 1.5rem;
  background-image: url("../assets/images/banner35.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  transition: transform 0.3s ease; /* 平滑过渡效果 */
}

.service-box:hover {
  transform: scale(1.05);
  transition: transform 0.3s ease; /* 平滑过渡效果 */
}

.introduce-box {
  height: 25rem;
  //background: #F5F5F5;
  //height: 28rem;

}

.product-item-left {
  width: 45vw;
  overflow: hidden;
  position: relative;
}

.product-item-right {
  width: 33vw;
  overflow: hidden;
  position: relative;

}

.product-img-box {
  width: 100%;
  min-height: 25rem;
  background: #F5F5F5;
  overflow: hidden;
  position: relative;
}

.product-item-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: transform 0.5s ease;
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  //background: rgba(0, 0, 0, 0.7);
  transition: all 0.6s ease;
  top: 17rem;
  left: 0;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
}

.mask-content {
  position: relative;
  bottom: -100%;
  //min-height: 5rem;
  //transition: all 0.6s ease;
}

.product-item-left:hover .product-item-img, .product-item-right:hover .product-item-img {
  transform: scale(1.2);
}

.product-item-left:hover .mask, .product-item-right:hover .mask {
  top: 0;
  bottom: 0;
  background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 50%);
  //background: rgba(0, 0, 0, 0.7);
}

.product-item-left:hover .mask-content, .product-item-right:hover .mask-content {
  bottom: 0;
}

@media (max-width: 767px) {
  //小
  .product-box {
    width: 100%;
  }
  .product-item-left, .product-item-right {
    width: 100%;
  }
  .service-box {
    width: 100vw;
  }
  .swiper-item {
    width: 100vw;
    padding: 5vh 5vw 0 5vw;
  }
  .banner-img {
    width: 90vw;
    height: 22vh;
  }
  .banner-img-box {
    text-align: center;
  }
  .list-item {
    width: 100%;
  }
  .introduce-box {
    flex-basis: 100vw;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .introduce-item {
    width: 100%;
  }
}

@media (min-width: 768px) {
  //大
  .product-box {
    width: 80%;
  }
  .list-item {
    width: 20%;
    flex-basis: 20rem;

  }
  .swiper-item {
    width: 50vw;
    padding: 5vh 0 0 5vw;
  }
  .banner-img {
    width: 30vw;
    height: 30vh;
  }
  .banner-img-box {
    padding-right: 2rem;
  }
  .text-btn {
    padding-left: 5rem;
    flex: 1;
  }
  .introduce-item {
    //width: 60%;
    width: 80%;
  }
}

.text-btn {
  .el-divider--horizontal {
    width: 8rem;
  }
}


.list-item {
  height: 22rem;
  overflow: hidden;
  margin-top: 1rem;
  //width: 20%;
  flex-shrink: 0;
  //width: 24.125rem;
  //padding: 5.625rem 2.8125rem;
  padding: 4rem 2rem;
  //height: 546px;
  border-radius: 0.5rem;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 1) 100%);
  box-shadow: 0 1.625rem 2.8125rem rgba(205, 205, 205, 0.25);
  transition: all 0.6s ease; /* 平滑过渡效果 */

}

.list-item:hover {
  //transform: scale(1.1); /* 放大1.1倍 */
  transition: all 0.6s ease; /* 平滑过渡效果 */
  padding: 2rem 2rem;
}

.list-item:hover .content-box {
  bottom: 0;
  //transform: scale(1);
  //transform-origin: bottom;
  transition: all 0.5s ease; /* 平滑过渡效果 */

}

.content-box {
  position: relative;
  bottom: -50%;
  min-height: 5rem;
  //transform: scale(0);
  transition: all 0.5s ease; /* 平滑过渡效果 */
}

.product-box {
  //width: 100%;
  margin: 0 auto;
  //padding: 8% 5% 5% 10%;
  position: relative;
  //height: 776px;
  //background: url("../assets/images/bgImg2.png") no-repeat;
  //background-size: 80%;

  .product-text {
    position: relative;
    text-align: left;
    margin-top: 6%;

    &:before {
      content: '';
      position: absolute;
      top: -3rem;
      left: 0;
      height: 1px;
      width: 7.5rem;
      background: #FFFFFF;
    }
  }

  .product-item {
    position: relative;
    overflow: hidden;
    //width: 20rem;
    //height: 25.5rem;
    width: 25rem;
    height: 28rem;
    //padding: 3.125rem;
    border-radius: 0.75rem;
    //background: rgba(68, 204, 136, 1);
    background: #FFFFFF;
    box-shadow: 0 3px 15px rgba(222, 222, 222, 0.25);


    .product-content {
      //width: 4.375rem;
      margin: 2.5rem auto 0;
      padding-top: 2.5rem;
      border-top: 1px solid #FFFFFF;
    }
  }

  .product-item::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 13rem;
    height: 5px;
    background-color: var(--theme-color);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }

  .product-item:hover::after {
    transform: scale(1);
    transform-origin: center;
  }

  .product-item:hover .bottom-btn-box {
    transform: scaleY(1);
    transform-origin: bottom;
  }

  .img-160 {
    width: 100%;
    height: 13rem;
  }

  .bottom-btn-box {
    position: absolute;
    padding: 2rem;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    transform: scaleY(0);
    transform-origin: bottom;
    transition: transform 0.3s ease-in-out;
  }
}

.evaluate-box {
  //margin: 30px auto 0;
  width: 100%;
  //overflow: hidden;
  //height: 380px;
  //transition: transform 0.5s;
  .el-carousel__item {
    //flex-basis: 18.75rem;
    //flex-shrink: 0;
    //width: 18.75rem;
    padding: 1.875rem;
    //margin: 0 20px;

    //background-color: #FFFFFF;
  }

  .evaluate-item {
    //box-sizing: content-box;
    //flex-basis: 18.75rem;
    //flex-shrink: 0;
    //min-width: 18.75rem;
    min-width: 22rem;
    width: 80%;
    padding: 1.875rem;
    margin: 0 auto;
    background-color: #FFFFFF;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);

    .evaluate-img {
      height: 2.25rem;
      //width: 100%;
      margin: 0 auto;
    }
  }

  //.evaluate-item:hover {
  //  background: #FFFFFF;
  //  background: rgba(255, 255, 255, 1);
  //  box-shadow: 0 7.5px 37.5px rgba(222, 222, 222, 0.25);
  //}
}

.left-box {
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 50%;
  line-height: 3.125rem;
  background: #FFFFFF;
  color: var(--theme-color);
  box-shadow: 0 7.5px 37.5px rgba(222, 222, 222, 0.25);

}

.left-box:hover {
  background: var(--theme-color);
  color: #FFFFFF;
}
</style>
