import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
import {BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './assets/css/my.css'

Vue.use(BootstrapVue)
Vue.config.productionTip = false

// window.baseUrl = 'http://localhost:3001';
window.mailUrl = 'https://tokisoft.cn:1123/mail'
window.imUrl = 'https://tokisoft.cn:1124'
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
