<template>
  <div class="header-box">
    <div class="flex justify-between nav-box align-center" style="position: fixed;top: 0;z-index: 999">
      <router-link to="/">
        <div class=" text-left">
          <el-image
              class="logo-img margin-0"
              :src="require('@/assets/images/logo2.png')"
              fit="fill"></el-image>
        </div>
      </router-link>
      <div class="nav-menu">
        <el-dropdown trigger="click" @command="gotoNav">
      <span class="el-icon-more padding-lr text-24">
<!--        <i class="el-icon-arrow-down el-icon-more"></i>-->
      </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in smallList" :key="item.id" :command="item.id">{{ item.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

      <div class="nav-right flex text-xl  justify-between align-center ">
        <div class="nav-item  padding-lr-xxl" :class="item.id===3?'btn-box':active===item.id?'color-g':'color-3'"
             @click="gotoNav(item.id)"
             v-for="(item,index) in list" :key="index">
          <!--          @mouseenter="changeShow(1,index)"-->
          <div :class="item.id===3?'active-btn':'name-box'">{{ item.name }}</div>
          <div :class="item.id===1?'menu-box':'menu-box-show'">
            <!--            v-show="item.id===1&&showMenu" @mouseleave="changeShow(0,1)""-->
            <div>
              <div class="color-3 text-df sub-nav" @click.stop="gotoNav(4)">糖厂农务管理系统</div>
            </div>
            <div class="margin-top">
              <div class="color-3 text-df sub-nav" @click.stop="gotoNav(5)">无人值守过磅管理系统</div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>
<script>
export default {
  name: "HeaderView",
  props: {
    index: {
      type: Number,
      default: 0,

    }
  },
  data() {
    return {
      list: [{id: 0, name: '首页'}, {id: 1, name: '产品'}, {id: 6, name: '服务'}, {id: 2, name: '关于我们'}, {
        id: 3,
        name: '联系我们'
      }],
      smallList: [{id: 0, name: '首页'}, {id: 4, name: '糖厂农务管理系统'}, {
        id: 5,
        name: '无人值守过磅管理系统'
      }, {id: 6, name: '服务'}, {
        id: 2,
        name: '关于我们'
      }, {id: 3, name: '联系我们'}],
      url: "require('@/assets/images/logo.jpg')",
      active: this.index,
      showMenu: false,
      drawer: false,
      direction: 'rtl',
    }
  },
  methods: {
    openMenu() {
      this.drawer = true;
    },
    changeShow(show, index) {
      // console.log(index,show)

      if (index === 1) {
        this.showMenu = show > 0
      } else {
        this.showMenu = false
      }
    },
    gotoNav(index) {
      if (this.active !== index && index !== 1) {
        this.active = index
        this.$emit('update:index', index);
        switch (index) {
          case 1:
            break;
          case 2:
            this.$router.push({path: 'about'}).catch(err => {
              // 处理错误，‌例如打印错误日志
              console.error(err);
            });
            break;
          case 3:
            this.$router.push({path: 'contact'}).catch(err => {
              // 处理错误，‌例如打印错误日志
              console.error(err);
            });
            break;
          case 4:
            this.$router.push({path: 'product'}).catch(err => {
              // 处理错误，‌例如打印错误日志
              console.error(err);
            });
            break;
          case 5:
            this.$router.push({path: 'productTwo'}).catch(err => {
              // 处理错误，‌例如打印错误日志
              console.error(err);
            });
            break;
          case 6:
            this.$router.push({path: 'service'}).catch(err => {
              // 处理错误，‌例如打印错误日志
              console.error(err);
            });
            break;
          default:
            this.$router.push({path: '/'}).catch(err => {
              // 处理错误，‌例如打印错误日志
              console.error(err);
            });
        }
      }


    }
  }
}
</script>
<style scoped lang="scss">
@media (max-width: 767px) {
  .nav-right {
    display: none;
  }
  .nav-menu {
    display: block;
  }
  .nav-box {
    padding: 0 2rem;
  }
}

@media (min-width: 768px) {
  .nav-menu {
    display: none;
  }
  .nav-box {
    padding: 0 5rem;
  }
}

.header-box {
  height: 4rem;
}

.nav-box {
  height: 4rem;
  width: 100%;
  background: #FFFFFF;
}

.el-dropdown-menu {
  text-align: center;
  right: 0;
  left: 0 !important;
}

.nav-item {
  position: relative;
  cursor: pointer;
}

.name-box {
  line-height: 5rem;
}

.name-box::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  height: 2px;
  width: 50%;
  margin: 0 auto;
  background-color: var(--theme-color);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease-in-out;
}

//.name-box:hover::after {
//  transform: scaleX(1);
//  transform-origin: center;
//  color: #44CC88;
//  font-weight: bold;
//}
.nav-item:hover .name-box {
  color: var(--theme-color);
  font-weight: bold;
}

.nav-item:hover .name-box::after {
  transform: scaleX(1);
  transform-origin: center;
}

.sub-nav {
  font-weight: normal;
}

.sub-nav:hover {
  color: var(--theme-color);
}

.color-g {
  color: var(--theme-color);
  font-weight: bold;
  position: relative;
}

.color-g::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  height: 2px;
  width: 50%;
  margin: 0 auto;
  background-color: var(--theme-color);
}

.btn-box {
  //width: 150px;
  margin-left: 1.5rem;
  height: 2.5rem;
  padding: 0 1.8rem;
  line-height: 2.5rem;
  border-radius: 4.6875rem;
  //background: #00C349;
  background: var(--theme-color);
  color: #FFFFFF;
  transition: transform 0.3s ease;
}

.btn-box:hover {
  transform: scale(1.1);
  background-color: #18C759;
}

.nav-item:hover .menu-box {
  display: block;
}

.menu-box {
  display: none;
  position: absolute;
  padding: 1.25rem 0;
  //top: 3.125rem;
  top: 5rem;
  width: 120%;
  left: -10%;
  background: #FFFFFF;
}

.menu-box-show {
  display: none;
}
</style>