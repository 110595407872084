<template>
  <div class="float-box flex flex-column">
    <el-popover
        placement="left"
        trigger="hover"
        content="电话咨询：0771-2867367">
      <i class="el-icon-phone-outline btn-box text-24" slot="reference"></i>
    </el-popover>
    <el-popover
        placement="left"
        trigger="hover"
        content="在线客服"
       >
      <i class="el-icon-service btn-box text-24" slot="reference"  @click="gotoChat"></i>
    </el-popover>
    <!--    <i class="el-icon-service btn-box text-24" @click="gotoChat"></i>-->
    <el-popover
        placement="left"
        trigger="hover">
      <el-image
          class="img-90"
          :src="require('@/assets/images/gzh.png')"
          fit="fill"></el-image>
      <i class="el-icon-full-screen btn-box text-24" slot="reference"></i>
    </el-popover>
    <i class="el-icon-arrow-up up-btn text-24" @click="gotoUp" v-show="show"></i>
  </div>
</template>
<script>

export default {
  name: "FloatBtn",
  data() {
    return {
      show: false
    }
  },
  mounted() {
// 监听滚动事件
    window.addEventListener('scroll', () => {
      // 获取当前滚动高度
      let scrollTop = window.scrollY;
      this.$nextTick(() => {
        // 在这里检查或操作DOM
        this.show = scrollTop > 0;
      });
    });
  },
  methods: {
    gotoChat() {
      window.open(window.imUrl+'/#/imclient', '_blank');
    },
    gotoUp() {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      })
    }
  }
}
</script>


<style scoped lang="scss">
.float-box {
  position: fixed;
  bottom: 50px;
  right: 30px;
  z-index: 999;
}

.up-btn {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  line-height: 50px;
  background: var(--theme-color);
  margin-top: 10px;
  color: #FFFFFF;
  box-shadow: 0 1.5px 15px rgba(0, 0, 0, 0.25);
}
.up-btn:hover {
  transform: scale(1.1); /* 放大1.1倍 */
  transition: transform 0.3s ease; /* 平滑过渡效果 */
}
.btn-box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  line-height: 50px;
  background: #FFFFFF;
  margin-top: 10px;
  color: #666666;
  box-shadow: 0 1.5px 15px rgba(0, 0, 0, 0.25);
}

.btn-box:hover {
  background: var(--theme-color);
  color: #FFFFFF;
  transform: scale(1.1); /* 放大1.1倍 */
  transition: transform 0.3s ease; /* 平滑过渡效果 */
}
</style>