<template>
  <div class="margin-top-xl footer-box">
    <div class="flex flex-wrap justify-between  " style="max-width: 70vw;margin: 0 auto">
      <div class="item-box padding-top-xl" v-for="(item,index) in list" :key="index" :class="index===5?'':'text-left'">
        <div class="text-df color-0 margin-bottom-sm " :class="index!==1&&index!==5?'pointer':''"
             @click="gotoLink(item.title)">{{ item.title }}
        </div>
        <div class=" color-6 " v-for="(subItem,subIndex) in item.list" :key="subIndex">
          <el-image v-if="index===5"
                    class="img-90"
                    :src="subItem"
                    fit="fill"></el-image>
          <el-link v-else type="info" style="color: #666666" class="text-sm"
                   @click="gotoLink(subItem)">{{ subItem }}
          </el-link>
        </div>

      </div>
    </div>
    <div class="text-left text-sm color-3" style="max-width: 70vw;margin: 1.25rem auto 0;">
      <div> 联系方式：咨询<span class="color-r pointer" @click="gotoChat"> 在线客服 </span>，或致电<span
          class="color-r"> 0771-2867367 </span>（周一至周五
        8:30-18:00）
      </div>
      <el-divider></el-divider>
      <div class="flex align-center  info-box">
        <div> 版权所有 © 南宁市通凯计算机软件有限责任公司</div>
        <el-image
            class="margin-left"
            style="width: 1.1rem"
            :src="require('@/assets/images/icon65.png')"
            fit="fill"></el-image>
        <a href="https://beian.miit.gov.cn/"
           rel="noreferrer" target="_blank" class="text-box">桂ICP备20001053号-1</a>
        <el-image
            class="margin-left-xl"
            style="width: 1rem"
            :src="require('@/assets/images/icon64.png')"
            fit="fill"></el-image>
        <a href="https://beian.mps.gov.cn/#/query/websearch?code=45010302003406"
           rel="noreferrer" target="_blank">桂公网安备45010302003406</a>
      </div>


    </div>
  </div>
</template>
<script>
export default {
  name: "FooterView",
  data() {
    return {
      list: [
        {title: '首页', list: []},
        {title: '产品', list: ['糖厂农务管理系统', '无人值守过磅管理系统']},
        {title: '服务', list: ['定制软件开发']},
        {title: '关于我们', list: ['公司概况', '发展历程', '企业荣誉', '企业文化']},
        {title: '联系我们', list: []},
        {title: '欢迎关注“甜蜜通”公众号', list: [require('@/assets/images/gzh.png')]}
      ]
    }
  },
  methods: {
    gotoChat() {
      window.open(window.imUrl + '/#/imclient', '_blank');
    },
    gotoLink(item) {
      if (item === '首页' && this.$router.currentRoute.path !== '/') {
        this.$router.push({path: '/'}).catch(err => {
          // 处理错误，‌例如打印错误日志
          console.error(err);
        });
        return
      }
      if (item === '糖厂农务管理系统' && this.$router.currentRoute.path !== '/product') {
        this.$router.push({path: 'product'}).catch(err => {
          // 处理错误，‌例如打印错误日志
          console.error(err);
        });
        return
      }
      if (item === '无人值守过磅管理系统' && this.$router.currentRoute.path !== '/productTwo') {
        this.$router.push({path: 'productTwo'}).catch(err => {
          // 处理错误，‌例如打印错误日志
          console.error(err);
        });
        return
      }
      if ((item === '服务' || item === '定制软件开发') && this.$router.currentRoute.path !== '/service') {
        this.$router.push({path: 'service'}).catch(err => {
          // 处理错误，‌例如打印错误日志
          console.error(err);
        });
        return
      }
      if (item === '关于我们' || item === '公司概况' || item === '企业荣誉' || item === '发展历程' || item === '企业文化') {
        if (this.$route.path === '/about') {
          this.$emit('changeUrl', item)
        } else {
          this.$router.push({name: 'about', params: {type: item}}).catch(err => {
            // 处理错误，‌例如打印错误日志
            console.error(err);
          });
        }
        return

      }
      if (item === '联系我们' && this.$router.currentRoute.path !== '/contact') {
        this.$router.push({path: 'contact'}).catch(err => {
          // 处理错误，‌例如打印错误日志
          console.error(err);
        });
      }
    },
  }
}
</script>
<style scoped lang="scss">
.footer-box {
  background: #F2F2F2;
  padding: 0.8rem 0 1.5rem;

}
.info-box{
  a {
    color: #333333;
    text-decoration: none;
    margin-left: 0.3125rem;
  }
  a:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
  }
}

.item-box {
  //padding: 1.25rem 2.5rem;
  flex-basis: 10rem;

}

.pointer {
  cursor: pointer;
}

.pointer:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.el-divider--horizontal {
  margin: 0.625rem 0;
}

.text-box {
  position: relative;
}

.text-box::after {
  content: ' ';
  width: 1px;
  height: 1.2rem;
  background: #c6c6c6;
  position: absolute;
  right: -0.75rem;
  top: -0.1rem;
}

</style>